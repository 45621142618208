<template>
   <ObiText>
      <ObiRow>
         <ObiCol sm="12">
            <ObiSessionLimit
               title="Zaman Limiti"
               :disabled="isLoading"
               v-model="formData.session_duration"
               :content="$t('time_limit_content')"
               :options="timeOptions"
            />
         </ObiCol>
      </ObiRow>

      <ObiRow class="mt-2">
         <ObiCol sm="12">
            <ObiSessionLimit
               title="Upload Hızı"
               :disabled="isLoading"
               v-model="formData.max_upload"
               :content="$t('upload_limit_content')"
               :options="speedOptions"
            />
         </ObiCol>
      </ObiRow>

      <ObiRow class="mt-2">
         <ObiCol sm="12">
            <ObiSessionLimit
               title="Download Hızı"
               :disabled="isLoading"
               v-model="formData.max_download"
               :content="$t('download_limit_content')"
               :options="speedOptions"
            />
         </ObiCol>
      </ObiRow>

      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('actions.save')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiText>
</template>

<script>
import IconLayers from '@/components/Icons/general/IconLayers.svg';

import Form from '@/libs/form';
import ObiSessionLimit from '@/components/UI/ObiSessionLimit';

export default {
   name: 'ObiUserProfile',
   components: {
      ObiSessionLimit,
   },
   i18n: {
      messages: {
         tr: {
            title: 'Yeni Kullanıcı Profili Ekle',
            subtitle: 'Aşağıdaki bilgileri girerek yeni Kullanıcı Profili ekleyebilirsiniz',
            form_title: 'Kullanıcı Profili Bilgilerini Giriniz',
            paid_sale: 'Misafirlerinize internet satışı yapmak için bu seçeneği aktifleştirin.',
            time_limit_content:
               'Konuklarınızın maksimum oturum süresini ayarlayın. Süre dolduktan sonra tekrar oturum açmaları gerekir.',
            download_limit_content: 'Konuklarınızın maksimum indirme hızını ayarlayın.',
            upload_limit_content: 'Konuklarınızın maksimum yükleme hızını ayarlayın.',
            fields: {
               name: 'Profil Adı',
               shared_users: 'Eş Zamanlı Cihaz Sayısı',
            },
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            title: 'Add New User Profile',
            subtitle: 'You can add a new User Profile by entering the information below.',
            form_title: 'Enter User Profile Information',
            paid_sale: 'Activate this option to sell internet access to your guests.',
            time_limit_content:
               'Set the maximum session duration for your guests. They will need to log in again after the time expires.',
            download_limit_content: 'Set the maximum download speed for your guests.',
            upload_limit_content: 'Set the maximum upload speed for your guests.',
            fields: {
               name: 'Profile Name',
               shared_users: 'Concurrent Device Limit',
            },
            actions: {
               save: 'Save',
            },
         },
      },
   },
   data() {
      return {
         IconLayers,
         isLoading: false,
         formData: Form.create(['name', 'session_duration', 'max_upload', 'max_download', 'shared_user', 'paid_sale'])
            .validate({
               name: 'required',
               shared_user: 'required',
            })
            .defaults({
               name: 'My User Profile',
               shared_user: 3,
               paid_sale: {
                  is_active: false,
                  option: 'euro',
                  value: 1,
               },
               session_duration: {
                  is_active: false,
                  option: 'day',
                  value: 1,
               },
               max_upload: {
                  is_active: false,
                  option: 'mbps',
                  value: 1,
               },
               max_download: {
                  is_active: false,
                  option: 'mbps',
                  value: 1,
               },
            }),
         timeOptions: [
            {
               label: 'Dakika',
               id: 'minute',
               min: 0,
               max: 60,
               step: 5,
            },
            {
               label: 'Saat',
               id: 'hour',
               min: 0,
               max: 24,
               step: 1,
            },
            {
               label: 'Gün',
               id: 'day',
               min: 0,
               max: 365,
               step: 1,
            },
         ],
         currencyOptions: [
            {
               label: 'EURO',
               id: 'eur',
               min: 0,
               max: 6000,
               step: 5,
            },
            {
               label: 'USD',
               id: 'usd',
               min: 0,
               max: 6000,
               step: 5,
            },
            {
               label: 'GBP',
               id: 'gbp',
               min: 0,
               max: 6000,
               step: 5,
            },
            {
               label: 'TRY',
               id: 'try',
               min: 0,
               max: 6000,
               step: 5,
            },
         ],
         speedOptions: [
            {
               label: 'Kbps',
               id: 'kbps',
               min: 0,
               max: 1024,
               step: 128,
            },
            {
               label: 'Mbps',
               id: 'mbps',
               min: 1,
               max: 100,
               step: 1,
            },
            {
               label: 'Gbps',
               id: 'gbps',
               min: 1,
               max: 100,
               step: 1,
            },
         ],
      };
   },
};
</script>
